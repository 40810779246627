
"use strict";

// Импортируем другие js-файлы

// Меню
(function() {
  var nav = document.querySelector(".mob-navigation");
  var navBtn = document.querySelector(".mob-navigation__toggle");
  var navInner = document.querySelector(".mob-navigation__inner");
  var navInnerLinks = document.querySelectorAll(".submenu>li>a");

  if (navBtn) {
    navBtn.addEventListener("click", function(evt) {
      evt.preventDefault();
      navInner.classList.add("active");
      navInner.classList.remove("closed");
      document.querySelector("body").classList.add("overlay");
    });
  }

  var links = nav.querySelectorAll(".mob-navigation a");
  if (links) {
    links.forEach(function(link) {
      link.addEventListener("click", function(evt) {
        if (link.nextElementSibling && !link.classList.contains("logo")) {
          evt.preventDefault();
          link.nextElementSibling.classList.toggle("active");
        }
      });
    });
  }
  if (navInnerLinks) {
    navInnerLinks.forEach(function(link) {
      link.classList.remove("active");
      link.addEventListener("click", function(evt) {
        link.classList.toggle("active");
      });
    });
  }

  var navBtnClosed = nav.querySelector(".mob-navigation__closed");
  if (navBtnClosed) {
    navBtnClosed.addEventListener("click", function(evt) {
      evt.preventDefault();
      navInner.classList.remove("active");
      navInner.classList.add("closed");
      document.querySelector("body").classList.remove("overlay");
    });
  }
})();
(function() {
  var links = document.querySelectorAll(".navigation__list a");

  if (links) {
    links.forEach(function(link) {
      link.addEventListener("click", function(evt) {
        var sublist = link.nextElementSibling;
        if (sublist) {
          evt.preventDefault();
          sublist.classList.add("visible");
          document.querySelector(".side-elements").style.minWidth = "35rem";
          document.querySelector("body").classList.add("overlay");
          document.querySelector(".js-btn-close").style.display = "block";
        }
      });
    });
  }

  var innerlinks = document.querySelectorAll(
    ".sidemenu .navigation ul>li>ul>li>a"
  );
  if (innerlinks) {
    innerlinks.forEach(function(link) {
      link.addEventListener("click", function(evt) {
        link.classList.toggle("active");
        var list = link.nextElementSibling;
        if (list) {
          evt.preventDefault();
          list.classList.toggle("active");
          list.classList.remove("visible");
        }
      });
    });
  }

  var btnCloseMenu = document.querySelector(".js-btn-close");
  if (btnCloseMenu) {
    btnCloseMenu.addEventListener("click", function(evt) {
      evt.preventDefault();
      document.querySelector(".side-elements").style.minWidth = "auto";

      var subList = document.querySelectorAll(".sidemenu .navigation>ul>li>ul");
      if (subList) {
        subList.forEach(function(list) {
          list.classList.remove("visible");
        });
      }

      document.querySelector("body").classList.remove("overlay");
      btnCloseMenu.style.display = "none";
    });
  }
})();
(function(){
  var links = document.querySelectorAll(".page-footer__list-link");

  links.forEach(function(link){
    link.addEventListener('click', function(evt){
      evt.preventDefault();
      link.parentElement.classList.toggle('active');
    })
  });
  document.addEventListener("DOMContentLoaded", function(evt) {
    var items = document.querySelectorAll(".page-footer__list-item");

    items.forEach(function(item) {
      item.classList.add("active");
    });
    window.onresize = function() {
      resize_info();
    };
  });

  function resize_info() {
    var items = document.querySelectorAll(".page-footer__list-item");
    if (window.outerWidth > 600) {
      items.forEach(function(item) {
        item.classList.add("active");
      });
    } else {
      items.forEach(function(item) {
        item.classList.remove("active");
      });
    }
  }
})();

// Блоки
(function() {
  var links = document.querySelectorAll(".page-section__link span");
  links.forEach(function(link) {
    if (link && (window.outerWidth < 600)) {
      link.innerHTML = "Ещё";
    }
  });
})();
(function() {
  var textBlocks = document.querySelectorAll(".block-preview__text");
  var titleBlocks = document.querySelectorAll(".block-preview__title");
  textBlocks.forEach(function(block) {
    var size = 128;

    if (block.innerText.length >= size) {
      var text = block.innerText.substring(0, size);
      console.log(text);
      var lastIndex = text.lastIndexOf(" "); // позиция последнего пробела
      var text = block.innerText.substring(0, lastIndex) + "..."; // обрезаем до последнего слова

      return (block.innerText = text);
    }
  });
  titleBlocks.forEach(function(title) {
    var size = 40;

    if (title.innerText.length >= size) {
      var text = title.innerText.substring(0, size);
      console.log(text);
      var lastIndex = text.lastIndexOf(" "); // позиция последнего пробела
      var text = title.innerText.substring(0, lastIndex) + "..."; // обрезаем до последнего слова

      return (title.innerText = text);
    }
  });
})();

// Form
var inputs = document.querySelectorAll(".page-form__inputfile");
inputs.forEach(function(input) {
  var label = input.nextElementSibling,
    labelVal = label.innerText;
  input.addEventListener("change", function(e) {
    var fileName = "";
    if (this.files && this.files.length > 1)
      fileName = "".replace("{count}", this.files.length);
    else fileName = e.target.value.split("\\").pop();
    if (fileName) label.innerText = fileName;
    else label.innerText = labelVal;
  });
  input.addEventListener("focus", function() {
    input.classList.add("has-focus");
  });
  input.addEventListener("blur", function() {
    input.classList.remove("has-focus");
  });
});


(function() {
  var popupOpenBtns = document.querySelectorAll(".js-popup-btn");

  function onPopupOpen(popup) {
    popup.parentElement.classList.add("popup-show");
    document.querySelector("body").classList.add("overlay");
  }

  function onPopuplose(popup) {
    popup
      .querySelector(".popup-close")
      .addEventListener("click", function(evt) {
        evt.preventDefault();
        popup.parentElement.classList.remove("popup-show");
        document.querySelector("body").classList.remove("overlay");
      });
  }

  if (popupOpenBtns) {
    popupOpenBtns.forEach(function(btn) {
      btn.addEventListener("click", function(evt) {
        evt.preventDefault();
        var nameBtn = btn.getAttribute("data-target");

        var popup = document.querySelector(
          ".popup[data-target=" + nameBtn + "]"
        );

        onPopupOpen(popup);
        onPopuplose(popup);
      });
    });
  }
})();
(function() {
  var sliderAll = [
    {
      id: "#js-slider-web",
      slideCount: 4,
      loopedSlidesCount: 1,
      slidesPerView320: 1.5,
      slidesPerView640: 2,
      slidesPerView1200: 3
    },
    {
      id: "#js-slider-design",
      slideCount: 4,
      loopedSlidesCount: 1,
      slidesPerView320: 1.5,
      slidesPerView640: 2,
      slidesPerView1200: 3
    },
    {
      id: "#js-slider-video",
      slideCount: "3",
      loopedSlidesCount: 1,
      slidesPerView320: 1.5,
      slidesPerView640: 2,
      slidesPerView1200: 2
    },
    {
      id: "#js-slider-news",
      slideCount: 4,
      loopedSlidesCount: 1,
      slidesPerView320: 1.5,
      slidesPerView640: 2,
      slidesPerView1200: 3
    },
    {
      id: "#js-slider-jobs",
      slideCount: 4,
      loopedSlidesCount: 4,
      slidesPerView320: 1.5,
      slidesPerView640: 2,
      slidesPerView1200: 3
    },
    {
      id: "#js-slider-history",
      slideCount: 2,
      loopedSlidesCount: 4,
      slidesPerView320: 1,
      slidesPerView640: 1,
      slidesPerView1200: 1
    }
  ];

  var sliderWebAll = [
    {
      id: "#js-slider-web-1",
      slidesPerView: 4,
      slidesPerViewMob: 2,
      spaceBetweenMob: 10
    },
    {
      id: "#js-slider-web-2",
      slidesPerView: 4,
      slidesPerViewMob: 1.5,
      spaceBetweenMob: 20
    },
    {
      id: "#js-slider-web-3",
      slidesPerView: 4,
      slidesPerViewMob: 1.5,
      spaceBetweenMob: 20
    },
    {
      id: "#js-slider-web-4",
      slidesPerView: 4,
      slidesPerViewMob: 1.5,
      spaceBetweenMob: 20
    },
    {
      id: "#js-slider-web-5",
      slidesPerView: 4,
      slidesPerViewMob: 1.5,
      spaceBetweenMob: 20
    },
    {
      id: "#js-slider-web-6",
      slidesPerView: 4,
      slidesPerViewMob: 1.5,
      spaceBetweenMob: 20
    }
  ];

  for (var i = 0; i < sliderAll.length; i++) {
    var slider = sliderAll[i];

    var swiper = new Swiper(slider.id, {
      direction: "horizontal",
      slidesPerView: slider.slideCount,
      spaceBetween: 15,
      loop: false,
      freeMode: true,
      autoHeight: true,

      spaceBetween: 10,
      speed: 700,
      loopedSlides: slider.loopedSlidesCount,
      // Responsive breakpoints
      breakpoints: {
        // when window width is <= 320px
        320: {
          slidesPerView: slider.slidesPerView320,
          spaceBetween: 10
        },
        // when window width is <= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is <= 640px
        640: {
          slidesPerView: sliderAll.slidesPerView640,
          spaceBetween: 15
        },
        // when window width is <= 1200px
        1200: {
          slidesPerView: sliderAll.slidesPerView1200,
          spaceBetween: 20
        }
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  }

  var swiperService = new Swiper("#js-slider-service", {
    direction: "vertical",
    slidesPerView: 1,
    spaceBetween: 15,
    loop: false,
    freeMode: true,
    touchMoveStopPropagation: true,

    spaceBetween: 10,
    speed: 700,
    loopedSlides: 1,
    // Responsive breakpoints
    breakpoints: {
      // when window width is <= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      // when window width is <= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is <= 640px
      640: {
        slidesPerView: 1,
        spaceBetween: 15
      },
      // when window width is <= 1200px
      1200: {
        slidesPerView: 1,
        spaceBetween: 15
      }
    },
    effect: "fade",
    parallax: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  });
  var swiperNews = new Swiper("#js-slider-news-2", {
    direction: "horizontal",
    slidesPerView: 1,
    spaceBetween: 15,
    loop: false,
    freeMode: true,
    autoHeight: true,

    spaceBetween: 10,
    speed: 700,
    loopedSlides: 1,
    // Responsive breakpoints
    breakpoints: {
      // when window width is <= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 10
      },
      // when window width is <= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is <= 640px
      640: {
        slidesPerView: 1,
        spaceBetween: 15
      },
      // when window width is <= 1200px
      1200: {
        slidesPerView: 1,
        spaceBetween: 15
      }
    },
    effect: "fade",
    parallax: true,
    autoplay: false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  });

  // breakpoint where swiper will be destroyed
  // and switches to a dual-column layout
  var breakpoint = window.matchMedia("(min-width:992px)");

  // keep track of swiper instances to destroy later
  var mySwiper;

  var breakpointChecker = function() {
    // if larger viewport and multi-row layout needed
    if (breakpoint.matches === true) {
      // clean up old instances and inline styles when available
      if (mySwiper !== undefined) mySwiper.destroy(true, true);

      // or/and do nothing
      return;

      // else if a small viewport and single column layout needed
    } else if (breakpoint.matches === false) {
      // fire small viewport version of swiper
      return enableSwiper();
    }
  };

  var enableSwiper = function() {
    for (var i = 0; i < sliderWebAll.length; i++) {
      var slider = sliderWebAll[i];

      mySwiper = new Swiper(slider.id, {
        direction: "horizontal",
        slidesPerView: slider.slidesPerView,
        spaceBetween: 15,
        loop: false,
        freeMode: true,

        speed: 700,
        loopedSlides: 1,
        breakpoints: {
          320: {
            slidesPerView: slider.slidesPerViewMob,
            spaceBetween: slider.spaceBetweenMob
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 15
          }
        },
        autoHeight: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
    }
  };

  // keep an eye on viewport size changes
  breakpoint.addListener(breakpointChecker);

  // kickstart
  breakpointChecker();

  var jsTriggers = document.querySelectorAll(".js-tab-trigger");
  jsTriggers.forEach(function(trigger) {
    trigger.addEventListener("click", function() {
      var id = this.getAttribute("data-tab"),
        content = document.querySelector(
          '.js-tab-content[data-tab="' + id + '"]'
        ),
        activeTrigger = document.querySelector(".js-tab-trigger.active"),
        activeContent = document.querySelector(".js-tab-content.active");

      // keep an eye on viewport size changes
      breakpoint.addListener(breakpointChecker);

      // kickstart
      breakpointChecker();

      activeTrigger.classList.remove("active");
      trigger.classList.add("active");

      activeContent.classList.remove("active");
      content.classList.add("active");
    });
  });
})(); /* IIFE end */
// (function() {
//   var jsTriggers = document.querySelectorAll(".js-tab-trigger");
//   jsTriggers.forEach(function(trigger) {
//     trigger.addEventListener("click", function() {
//       var id = this.getAttribute("data-tab"),
//         content = document.querySelector(
//           '.js-tab-content[data-tab="' + id + '"]'
//         ),
//         activeTrigger = document.querySelector(".js-tab-trigger.active"),
//         activeContent = document.querySelector(".js-tab-content.active");

//         enableSwiper();

//       activeTrigger.classList.remove("active");
//       trigger.classList.add("active");

//       activeContent.classList.remove("active");
//       content.classList.add("active");
//     });
//   });
// })();